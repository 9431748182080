import React from 'react'
import useWindowSize from '../hooks/useWindowSize'

const Logos = () => {

    const { width } = useWindowSize()

    return (
        width <= 320 ? <img src="https://ik.imagekit.io/238r9emirjj/new-certificate-changes/Logo%20Small%20Screen%20(1).svg?updatedAt=1732964984986" className="mt-4 lg:mt-6" width={204} height={34} alt="" /> : <div className='w-fit mx-auto text-white flex items-center gap-2 mt-4 lg:mt-6'>
            <div className='w-fit bg-white/10 flex items-center p-2 rounded-md gap-2'>
                <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/image%2011.svg?updatedAt=1728356261811" loading="eager" alt="" width={24} height={24} className='w-full h-full'/>
                <p className='font-medium text-xs'>LearnTube.ai</p>
            </div>
            <div className='h-[36px] w-[2px] bg-white/20' />
            <div className='flex flex-col items-center'>
                <p className='text-xxs text-white/50'>backed by</p>
                <div className='flex gap-1 items-center'>
                    <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/use2460.svg?updatedAt=1728356838222" alt="" width={40} height={13} className='w-10 h-auto' />
                    <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/text3075.svg?updatedAt=1728356926579" alt="" width={64} height={13} className='w-16 h-auto' />
                </div>
            </div>
        </div>
    )
}

export default Logos